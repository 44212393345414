<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
      class="d-flex align-items-center justify-content-between" 
      style="width: 100%; cursor: pointer;"
      @click="navigateToDashboard"
    >
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
    </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <div></div>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
    <div class="card-container" style="width: 400px; max-width: 100%;">
      <div v-if="isDataLoaded" class="card mb-3">
        <div class="card-body">
          <h5 class="text-left mb-2 mt-4">¡Tu pago ha sido recibido exitosamente!</h5>
            <p style="margin-top: 20px;">El pedido tarda de 7 a 10 dias habiles en llegar a tu domicilio ya que los productos se mandan a fabricar directo al laboratorio y personalizados para ti. 
              En cuanto salga de produccion te enviaremos la guia de rastreo. No dudes en contactarnos por redes sociales o al whatsapp de soporte si tienes alguna duda.</p>



            <p >
              Revisa que los datos de envio sean correctos.
            </p>
            <div class="card">
              <div class="card-body">
                <h2 class="card-title text-center mb-4">Datos de envio</h2>
                <form @submit.prevent="createDeilveryAddress()" style="text-align: left;">
                  <div class="mb-3" >
                    Nombre completo
                      <input type="text" class="form-control" id="name" v-model="this.address.name" placeholder="Ingresa tu nombre completo" required>
                    </div>
                  <div class="mb-3">
                    Calle
                    <input type="text" class="form-control" id="street" v-model="this.address.street" placeholder="Ingresa tu calle" required>
                  </div>
                    <div class="mb-3">
                      Numero de domicilio
                      <input type="text" class="form-control" id="house_number" v-model="this.address.number" placeholder="Ingresa el numero de domicilio" required>
                    </div>
                    <div class="mb-3">
                      Codigo postal
                      <input type="text" class="form-control" id="zip_code" v-model="this.address.zip" placeholder="Ingresa tu codigo postal" required>
                    </div>
                    <div class="mb-3">
                      Colonia
                      <input type="text" class="form-control" id="district" v-model="this.address.district" placeholder="Ingresa tu colonia" required>
                    </div>
                    <div class="mb-3">
                      Ciudad
                      <input type="text" class="form-control" id="city" v-model="this.address.city" placeholder="Ingresa tu ciudad" required>
                    </div>
                    <div class="mb-3">
                      Estado
                      <input type="text" class="form-control" id="state" v-model="this.address.state" placeholder="Ingresa tu estado" required>
                    </div>
                    <div class="mb-3">
                      Referencias
                      <input type="text" class="form-control" id="guidelines" v-model="this.address.guidelines" placeholder="Ingresa referencias de tu domicilio" required>
                    </div>
                    <div class="mb-3">
                      Entre calles
                      <input type="text" class="form-control" id="is_between" v-model="this.address.is_between" placeholder="Ingresa las entre calles" required>
                    </div>
  

                    <div style="text-align:center">
                      <button type="submit" class="btn btn-primary" style="width: 80%;">Guardar direccion</button>
                    </div>
                </form>
              </div>
            </div>
            
      </div>
    </div>
  </div>
    </div>
  </div>
    
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapActions, mapState } from 'vuex';
import useFacebookPixel from '@/composables/useFacebookPixel';

  import axios from 'axios';
  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', {content_name: 'Pharmacy Payment Success' });
      //track('Purchase', { value: 1400.00, currency: 'MXN', content_name: 'Pharmacy Payment Success' })
    });

    return {};
  }, 
  data() {
    return {
      nextChargeDate: '',
      isDataLoaded: false,
      address: {
      id: null,
      user_id: null,
      name: '',
      street: '',
      number: '',
      zip: '',
      district: '',
      city: '',
      state: '',
      guidelines: '',
      is_between: '',
      phone: '',
      },
      mode: 0,
      
      HeaderLogo: require('@/assets/dermapp-white.png')
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday']),
    },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          const token = localStorage.getItem('Token');
    axios.get(`${this.$baseURL}/api/user-data-v2`, {
      params: {
        email: this.email || this.$route.query.email,
        page: 'Pharmacy Payment Success'
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      const data = response.data.data;
      this.updateUserId(data.id);
      this.updateEmail(data.email);
      this.updatePhone(data.phone, data.country);
      this.updateCountry(data.country);
      this.updateCredits(data.credits);
      this.updateReferralCode(data.referral_code);

  
      axios.get(`${this.$baseURL}/api/user-address`, {
      params: {
        user_id: data.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((address_response) => {
      if(address_response.data.success==true){
        this.mode = 1;
        this.address = address_response.data.data;
        this.address.user_id = this.userId;
      }
      
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
      this.isDataLoaded=true;
      
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

    
  },
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },
    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),
    
    async createDeilveryAddress() {

      if(this.mode == 0) {
        const response = await axios.post(`${this.$baseURL}/api/create-delivery-address`, this.address);
        if (response.status === 200) {
          this.$router.push({ name: 'PatientDashboard'});
        }
      } else {
        const response = await axios.put(`${this.$baseURL}/api/update-delivery-address`, this.address);
        if (response.status === 200) {
          this.$router.push({ name: 'PatientDashboard'});
        }
      }
    }
  
  },
};
</script>

<style scoped>

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}

</style>
