<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
      class="d-flex align-items-center justify-content-between" 
      style="width: 100%; cursor: pointer;"
      @click="navigateToDashboard"
    >
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
    </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center" >
        <div class="col-md-8">
          <div class="card mb-2">
            <div class="card-body mb-2">
              <h5 class="text-center mb-2 mt-4">Método de Pago</h5>

              <h4 class="card-title mb-4"></h4>
              <p>
                Pide tus productos a cualquier parte de México!<br><br>

                ¿Cuál es el metodo de pago<br> de tu preferencia?
              </p>
              <div class="card mb-2">
            <div class="card-body">
              <h5 class=" mb-3">Tarjeta de débito o crédito</h5>
              <button class="btn btn-primary" style="width:80%"  @click="cardPayment()">Pagar con tarjeta</button>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body">
              <h5 class="card-title mb-3">Transferencia interbancaria</h5>
              <router-link :to="{ name: 'PharmacyWireTransfer', params: { id: this.$route.params.id }}" class="btn btn-primary" style="width:80%">Pagar por transferencia</router-link>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class=" mb-3">Depósito en efectivo</h5>
              <router-link :to="{ name: 'PharmacyCashPayments', params: { id: this.$route.params.id }}" class="btn btn-primary" style="width:80%">Pagar en efectivo</router-link>
            </div>
          </div>
            </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState, mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import Swal from 'sweetalert2';


import useFacebookPixel from '@/composables/useFacebookPixel';

import { onMounted } from 'vue';

export default {
  setup() {
  const { track } = useFacebookPixel();

  onMounted(() => {
    track('ViewContent', { content_name: 'Pharmacy Payment Method' });
    track('SubmitApplication', { content_name: 'Create Pharmacy Order' });

  });

  return {};
},

  data() {
    return {
      assessment_id: null,
      order: {},
      isDataLoaded: false,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      BackIcon: require('@/assets/arrow-back.png'),
      discount: 0,

      treatment: [],
      tier: 0,
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName','profileSubscription' ,'primaryName', 'primaryBirthday']),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          this.assessment_id = this.$route.params.id;
// Fetch the user data
axios.get(`${this.$baseURL}/api/user-by-assessment`, {
    params: {
      assessment_id: this.assessment_id || this.$route.params.id,
    },
  })
  .then((response) => {
    const data = response.data.data;
    this.tier = data.profile.subscription_tier || 0;
    this.updateUserId(data.user.id);
    this.updateEmail(data.user.email);
    this.updatePhone(data.user.phone, data.user.country);
    this.updateCountry(data.user.country);
    this.updateCredits(data.user.credits);
    this.updateReferralCode(data.user.referral_code);
    this.updateProfileId(data.profile.id);
      this.updateProfileName(data.profile.name.split(' ')[0]);
      this.updateProfileSex(data.profile.sex);
      this.updateProfileSubscription(data.profile.subscription);

      axios.get(`${this.$baseURL}/api/get-latest-pharmacy_order`, {
      params: {
        assessment_id: this.assessment_id || this.$route.params.id,
      },
    })
    .then((order_response) => {
      this.order = order_response.data.data;
      if (this.credits > this.order.total) {
              this.discount = this.order.total;
            } else {
              this.discount = this.credits;
            }
            this.discount=Math.floor(this.discount )
      this.isDataLoaded = true;
      
    })
    .catch((error) => {
      console.error("Error fetching diagnosis data:", error);
    });
  })
  .catch((error) => {
    console.error("Error fetching user data:", error);
  });
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

},
methods: {
  navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

  ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

  async cardPayment() {
  const stripe = await loadStripe(`${this.$loadstripepk}`);

  if (this.discount > 0) {
  Swal.fire({
    title: '¿Deseas aplicar tus créditos como descuento?',
    text: `Tienes ${this.discount} créditos disponibles.`,
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Aplicar créditos',
    cancelButtonText: 'No aplicar créditos',
    cancelButtonColor: '#0d6efd',
    confirmButtonColor: '#0d6efd',
  })
  .then((result) => {
    let discountAmount = 0;

    if (result.isConfirmed) {
      // User chose to apply the discount
      discountAmount = this.discount;
    } else {
      // User chose not to apply the discount
      discountAmount = 0;
    }

    axios.post(`${this.$baseURL}/api/create-custom-checkout-session`, {
      user_id: this.userId,
      email: this.email,
      products: JSON.parse(this.order.products), // Ensure this property matches the backend expectation
      order_id: this.order.id,
      payment_method: 'card',
      phone: this.phone,
      patient_name: this.profileName,
      discountAmount: discountAmount, // Apply the selected discount
      profileSubscription: this.profileSubscription,
      subscription_tier: this.tier
    })
    .then((response) => {
      const sessionId = response.data.sessionId;
      stripe.redirectToCheckout({ sessionId });
    })
    .catch((error) => {
      console.error('Error creating checkout session:', error);
    });
  });
} else {
  // If no credits, proceed without confirmation
  axios.post(`${this.$baseURL}/api/create-custom-checkout-session`, {
    user_id: this.userId,
    email: this.email,
    products: JSON.parse(this.order.products), // Ensure this property matches the backend expectation
    order_id: this.order.id,
    payment_method: 'card',
    phone: this.phone,
    patient_name: this.profileName,
    discountAmount: 0, // No discount
    profileSubscription: this.profileSubscription,
    subscription_tier: this.tier
  })
  .then((response) => {
    const sessionId = response.data.sessionId;
    stripe.redirectToCheckout({ sessionId });
  })
  .catch((error) => {
    console.error('Error creating checkout session:', error);
  });
}

},

},
};
</script>

<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}


.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}

</style>
