<template>
  <div style="background-color:white; margin-top:-10px; padding-top:10px; padding-bottom:10px">
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px; background-color: white">
      <div 
  class="d-flex align-items-center justify-content-between" 
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid #38b6ff; border-radius: 10px; background: none; color: #38b6ff; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <h1 style="margin-left: 10px; font-weight: bold; color: black; margin-top:20px; margin-bottom:20px; text-align:left">
            Mi carrito
          </h1>            
          <div class="card-body" style="height: auto;">
            <!-- Products List -->
            <div v-for="(product, index) in this.products" :key="index" class="mb-4">
              <div
                class="card"
                :class="{ selected: product.selected_option === 'commercial' }"
              >
                <div class="card-content">
                  <div class="card-header" style="border-bottom:white">
                    <p style="font-size: 14px; width:65%; font-weight: bold; text-align:left">{{ product.name.toUpperCase() }}</p>
                    <p style="font-size: 14px; width:35%; text-align:right; font-weight: bold;">
                      MX${{ product.priceIVA }}
                    </p>
                  </div>
                  <div class="card-footer" style="border-top:white">
                    <div style="margin:0px">
                      <!-- Bootstrap Split Button Dose Selector -->
                    <div class="btn-group" v-if="product.doses && product.doses[0]!=''">
                      <button 
                        type="button" 
                        class="btn btn-white text-black border">
                        {{ product.doses[product.index || 0] }}
                      </button>
                      <button 
                        type="button" 
                        class="btn btn-primary dropdown-toggle dropdown-toggle-split" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false" 
                        style="background-color: #0D80FF;">
                        <span class="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu">
                        <li v-for="(dose, idx) in product.doses" :key="idx">
                          <a 
                            class="dropdown-item" 
                            href="#" 
                            @click.prevent="updateDose(idx, product)">
                            {{ dose }}
                          </a>
                        </li>
                      </ul>
                    </div> 
                    </div>
                    <div class="card-actions">
                      <img
                        :src="lessIcon"
                        @click="decreaseQuantity(product)"
                        class="icon"
                      />
                      <span style="margin: 0 5px;">{{ product.quantity }}</span>
                      <img
                        :src="addIcon"
                        @click="increaseQuantity(product)"
                        class="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Summary -->
            <div class="subtotal-container">
              <p>Subtotal</p>  
              <p>MX${{ calculateSubtotal }}</p>
            </div>
            <div class="shippment-container">
              <p class="shippment-text">
                Envío
                <button @click="showShippingInfo" class="info-button" type="button" >
                  <img :src="infoBlackIcon" alt="Info" class="info-icon" />
                </button>                                          
              </p>  
              <p>MX${{ (150.00).toFixed(2) }}</p> 
            </div>
            <div class="cashback-container">
              <p>Cashback</p>  
              <p>MX$-{{ calculateDiscount }}</p> 
            </div>
            <div class="subtotal-container" style="font-size:20px">
              <p>Total</p>  
              <p>MX${{ calculateTotal }}</p>
            </div>
            <div style="color:white; text-align:left; margin-left: 30px; margin-bottom:10px">
              <input required class="form-check-input" type="checkbox" id="TermsYes" v-model="terms" style="margin-top: 20px; margin-right:10px">
              <label style="margin-top: 17px; color: black" class="form-check-label" for="TermsYes">
                  Acepto los 
                  <a href="https://www.dermapp.com/terminos-y-condiciones" target="_blank" style="color: black; text-decoration: underline;">términos y condiciones</a>
              </label>
            </div>
            <!-- Action Buttons -->
            <div class="text-center">
              <button @click="toCart()" class="btn-blue">Pagar tratamiento</button>
            </div>
            <div class="text-center">
              <button @click="toAssessment()" class="btn-transparent">Seguir comprando</button>
            </div> 
          </div>
        </div>
      </div>
    </div>
     <!-- Integrar el ShippingModal -->
     <ShippingModal
     :visible="isShippingModalVisible"
     title="Información de Envío"
     message="Los envíos suelen tardar entre 7 y 10 días hábiles en llegar a tu domicilio. Esto puede variar según tu ubicación y disponibilidad del producto. Podrás consultar el estado de tu pedido en la sección <strong>'Mis pedidos'</strong> una vez realizada la compra."
     linkText="AQUÍ"
     linkUrl="https://www.o-lab.mx/" 
     @close="isShippingModalVisible = false"
   />
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState } from 'vuex';
import axios from 'axios';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';
import Swal from 'sweetalert2';
import ShippingModal from '@/views/ShippingModal.vue'; 



export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', { content_name: 'Patient dashboard' });
    });

    return {};
  },
  data() {
    return {
      orders: [],
      products: [],
      shipping: null, 
      isDataLoaded: false,
      isShippingModalVisible: false, // Controla la visibilidad del modal
      HeaderLogo: require('@/assets/dermapp-sky.png'),
      addIcon: require('@/assets/shop-add-icon.webp'),
      lessIcon: require('@/assets/shop-less-icon.webp'),
      BackIcon: require('@/assets/arrow-back.png'),
      infoBlackIcon: require('@/assets/info-black-icon.webp'), // Importando el nuevo icono de info
      terms: false,
      discount: 0,
      subtotal: 0,

    };
  },
  computed: {
  ...mapState(['userId', 'credits']),
  calculateSubtotal(){
    let temp_subtotal = 0;
this.products.forEach(product => {
  temp_subtotal += product.priceIVA * product.quantity;
});
  return temp_subtotal.toFixed(2);
},
  calculateDiscount(){
    let temp_discount = 0;
    if (this.credits > this.calculateSubtotal) {
            temp_discount = this.calculateSubtotal;
          } else {
            temp_discount = this.credits;
          }
    return Math.floor(temp_discount);
  },
  calculateTotal(){
    let temp_total = 0;
    temp_total = this.calculateSubtotal - this.calculateDiscount + 150;
    return temp_total.toFixed(2);
  }
},


  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true);
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);

          // Fetch the order data
          const orders_response = await axios.get(`${this.$baseURL}/api/order-by-id`, {
            params: { order_id: this.$route.params.id },
          });
          this.orders.push(orders_response.data.order);
          this.products = JSON.parse(this.orders[0].products).slice(0,-1);
          this.shipping = JSON.parse(this.orders[0].products)[JSON.parse(this.orders[0].products).length-1];

          if (this.credits > this.orders[0].total) {
            this.discount = this.orders[0].total;
          } else {
            this.discount = this.credits;
          }
          this.isDataLoaded = true;
        } catch (error) {
          console.error('Error al renovar el token:', error);
          if (
            error.code === 'auth/id-token-expired' ||
            error.code === 'auth/user-token-expired' ||
            error.message.includes('Token')
          ) {
            localStorage.removeItem('Token');
            this.$router.push('/SignIn');
          }
        }
      } else {
        this.$router.push('/SignIn');
      }
    });
  },
  components: {
    ShippingModal,
  },
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

    async toCart() {
      if (!this.terms) {
        Swal.fire({
          icon: "warning",
          text: "Por favor acepta los términos y condiciones para continuar.",
          confirmButtonText: "Entendido",
          confirmButtonColor: "#0d6efd",
        });
      } else {
        let total = this.calculateSubtotal + 150;
        this.products.push(this.shipping);
        let checkout = {
          order_id: this.orders[0].id,
          products: this.products,
          total: total,
        };
        const response = await axios.put(`${this.$baseURL}/api/update-order`, checkout);
        if (response.status === 200) {
          this.$router.push(`/delivery-address/${this.orders[0].assessment_id}`);
        } else {
          console.error('Error creating order:', response);
        }
      }
    },

    formatDoses(doses) {
      if (doses === undefined) {
        doses = [];
      }
      if (typeof doses === 'string') {
        doses = doses.slice(1, -1);
        doses = doses.split(',').map((item) => item.trim());
      }
      if (doses.length === 0) {
        doses = ['tamaño único'];
      }
      return doses;
    },
      // Function to handle the dose change
      updateDose(index, product) {
        product.index = index;
        product.dose = product.doses[product.index];
        product.price = product.prices[product.index].toString();
        product.priceIVA = product.pricesIVA[product.index].toString();
    },
    
    toAssessment() {
      this.$router.push(`/assessment-diagnosis-new/${this.orders[0].assessment_id}`);    
    },
    increaseQuantity(product) {
      product.quantity += 1;
    },
    decreaseQuantity(product) {
      if (product.quantity > 1) {
        product.quantity -= 1;
      }
    },
    // Mostrar mensaje informativo usando el modal personalizado
    showShippingInfo(event) {
      // Evitar el comportamiento por defecto y detener la propagación del evento
      event.preventDefault();
      event.stopPropagation();
      this.isShippingModalVisible = true; // Abre el modal
    }
  },
};
</script>

<style scoped>

.btn-transparent {
  background: transparent;
  border: 2px solid black;
  color: black;
  border-radius: 15px;
  font-family: 'Comfortaa', cursive;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px 0; /* Adjust padding as needed */
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.btn-transparent:hover {
  background-color: black;
  color: white;
}

.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}

.dose-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
}
.subtotal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}

.shippment-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  color: #000;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}

.cashback-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #ccc;
  font-weight: normal;
  color: orange;
  font-size: 14px;
  margin-left:20px;
  margin-right:20px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.btn-blue {
  background: blue;
  border: 2px solid blue;
  color: white;
  border-radius: 15px;
  font-family: 'Comfortaa', cursive;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px 0;
  text-align: center;
}

.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: #38b6ff;
  cursor: pointer;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Card Styles */
.card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.card-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 10px 0;
}

.card-footer {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 0;
  margin: 0;
}

.card-actions {
  display: flex;
  align-items: center;
}

/* Estilos para el ícono de información */
.info-icon {
  width: 12px; /* Ajusta el tamaño según sea necesario */
  height: 12px;
  margin-left: 0.5px; /* Espacio entre el texto y el ícono */
  margin-bottom: 90%;
  vertical-align: corner; /* Alinea el ícono verticalmente con el texto */
  cursor: pointer; /* Cambia el cursor a pointer */
}

.info-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.btn-group .dropdown-toggle-split {
  background-image: url('@/assets/drop-arrow.png');
  background-repeat: no-repeat;
  background-position: center; /* Adjust to position it correctly */
  background-size: 15px 15px; /* Adjust the size of your custom arrow */
  padding-right: 30px; /* Adjust padding to accommodate the image */
}

.btn-group .dropdown-toggle-split::after {
  display: none; /* Hides the default arrow */
}

/* Adjust the height of the split button */
.btn-group .dropdown-toggle-split,
.btn-group .btn {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px; /* Adjust font size if needed */
  line-height: 1.2; /* Adjust the line-height to control text height */
  height: auto; /* Let the height adjust automatically based on the content */
}

/* Customize the dropdown menu background and borders */
.btn-group .dropdown-menu {
  background-color: #f8f9fa; /* Custom background color */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ddd; /* Custom border color */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Custom shadow */
}

/* Customize the dropdown items */
.btn-group .dropdown-menu .dropdown-item {
  font-size: 14px; /* Adjust the font size */
  padding: 10px 20px; /* Adjust the padding for more space */
  color: #333; /* Custom text color */
  background-color: #f8f9fa; /* Custom background color */
  transition: background-color 0.2s ease; /* Smooth hover transition */
}

/* Import Comfortaa font */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');
</style>
